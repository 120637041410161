import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.AWS_KEY,
  secretAccessKey: process.env.AWS_SECRET,
  region: process.env.region
});

const s3 = new AWS.S3();

export const ListS3BucketImages = async ({bucketName, folderPath}) => {
  const params = {
    Bucket: bucketName,
    Prefix: folderPath
  };
  try {
    const data = await s3.listObjectsV2(params).promise();
    if (data.Contents.length === 0) {
      console.log('No images found in the folder.');
      return;
    }

    const imageKeys = data.Contents
      .filter(obj => obj.Key.match(/\.(jpg|jpeg|png)$/i))
      .map(obj => obj.Key);
    console.log(imageKeys);
    return { imageKeys };
  } catch (error) {
    console.log('Error in Listing Images', error);
  }
};

export const GetS3ImageUrl = ({ bucketName, key }) => {  
  const encodedKey = encodeURIComponent(key);
  const url = `https://${bucketName}.s3.amazonaws.com/${encodedKey}`;
  return url;
};

export const UploadFileToUrl = async (url, file) => {
  console.log(file)
  const options = {
    method: 'PUT',
    body: file,
    headers:{
      'Content-Type': file.type,
    },
  };
  const response = await fetch(url, options);
  return response;
};

export const DownloadImageFromUrl = (url, name) => {
  fetch(url)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => console.log(`Err: ${err}`));
};
